var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-8 pr-8"},[_c('div',{staticClass:"mb-xl-margin-file-sign"},[_c('v-row',{staticClass:"mt-10"},[_c('v-col',{staticClass:"text-left",attrs:{"cols":"6"}},[_c('h1',{staticClass:"reference__title"},[_vm._v("Referidos")])])],1),_c('v-card',{staticClass:"reference__card px-8 mt-8",attrs:{"outlined":"","elevation":"0"}},[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.listStatus,"label":"Estado","item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"color":"#afb0b0"}},[_vm._v(" mdi-filter-outline ")])]},proxy:true}]),model:{value:(_vm.statusId),callback:function ($$v) {_vm.statusId=$$v},expression:"statusId"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":150,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Seleccionar rango de fechas","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dateRangeText),callback:function ($$v) {_vm.dateRangeText=$$v},expression:"dateRangeText"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"range":"","max":_vm.maxDate},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false,_vm.dates=[],_vm.getReference()}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.searchForDate()}}},[_vm._v(" Buscar ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar"},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{attrs:{"color":"#afb0b0"}},[_vm._v(" mdi-magnify ")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"mt-2 ml-2",staticStyle:{"color":"#fff"},style:({
                'background-color': hover ? '#324CA1' : '#466BE3',
              }),attrs:{"block":"","rounded":""},on:{"click":function($event){return _vm.download()}}},[_vm._v(" Descargar ")])]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0 reference__table",staticStyle:{"background-color":"#f9f9f900"},attrs:{"search":_vm.search,"loading":_vm.loading.table,"items-per-page":15,"headers":_vm.headers,"items":_vm.items,"item-key":"name","footer-props":{ itemsPerPageOptions: [10, 15, 30] }},scopedSlots:_vm._u([{key:"no-results",fn:function(){return [_c('v-alert',{attrs:{"icon":"mdi-information-outline","prominent":"","text":"","type":"info"}},[_c('b',[_vm._v("No se encontraron resultados")])])]},proxy:true},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"icon":"mdi-information-outline","prominent":"","text":"","type":"info"}},[_c('b',[_vm._v("No se encontró información.")])])]},proxy:true},{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"#214484","height":5,"indeterminate":"","stream":""}})]},proxy:true},{key:"item.img_referrer",fn:function(ref){
              var item = ref.item;
return [_c('div',{staticClass:"float-left mt-3"},[_c('img',{staticStyle:{"border-radius":"120px","border":"1.3px solid #466be3"},attrs:{"width":"32","height":"32x","src":item.img_referrer}})])]}},{key:"item.document_number_referrer",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"max-width":"300","color":"#000","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('p',{staticClass:"mb-0 mt-2 text-1-1-lines"},[_c('strong',[_vm._v(" "+_vm._s(item.full_name_referrer)+" ")])]),_c('p',{staticClass:"mb-0 mb-2 text-1-1-lines"},[_vm._v(" "+_vm._s(item.document_number_referrer)+" ")])])]}}],null,true)},[_c('span',[_c('strong',[_vm._v("INFORMACIÓN REFERIDOR")]),_c('br'),_c('strong',[_vm._v("NOMBRE: ")]),_vm._v(" "+_vm._s(item.full_name_referrer)+" "),_c('br'),_c('strong',[_vm._v("DOCUMENTO: ")]),_vm._v(" "+_vm._s(item.document_number_referrer)+" ")])])]}},{key:"item.status_id",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"max-width":"300","color":"#000","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('p',{staticClass:"mb-0 mt-2 text-1-1-lines"},[(item.status_id==1)?_c('strong',[_vm._v(" Activo")]):_vm._e(),(item.status_id==2)?_c('strong',[_vm._v(" Inactivo")]):_vm._e(),(item.status_id==8)?_c('strong',[_vm._v(" En proceso")]):_vm._e()])])]}}],null,true)})]}},{key:"item.name_referred",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"max-width":"300","color":"#000","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('p',{staticClass:"mb-0 mt-2 text-1-1-lines"},[_c('strong',[_vm._v(" "+_vm._s(item.name_referred)+" ")])]),_c('p',{staticClass:"mb-0 mb-2 text-1-1-lines"},[_vm._v(" "+_vm._s(item.document_number_referred)+" ")])])]}}],null,true)},[_c('span',[_c('strong',[_vm._v("INFORMACIÓN DEL REFERIDO ")]),_vm._v(" "),_c('br'),_c('strong',[_vm._v("NOMBRE: ")]),_vm._v(" "+_vm._s(item.name_referred)+" "),_c('br'),_c('strong',[_vm._v("DOCUMENTO: ")]),_vm._v(" "+_vm._s(item.document_number_referred)+" ")])])]}},{key:"item.document_number_referred",fn:function(ref){
              var item = ref.item;
return [_c('v-tooltip',{attrs:{"max-width":"350","color":"#000","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('p',{staticClass:"mb-0 mt-2 text-1-1-lines"},[_c('strong',[_vm._v(" "+_vm._s(item.email_referred)+" ")])]),_c('p',{staticClass:"mb-0 mb-2 text-1-1-lines"},[_vm._v(" "+_vm._s(item.phone_referred)+" ")])])]}}],null,true)},[_c('span',[_c('strong',[_vm._v("CONTACTO DEL REFERIDO ")]),_vm._v(" "),_c('br'),_c('strong',[_vm._v("CORREO: ")]),_vm._v(" "+_vm._s(item.email_referred)+" "),_c('br'),_c('strong',[_vm._v("CELULAR: ")]),_vm._v(" "+_vm._s(item.phone_referred)+" ")])])]}},{key:"item.status_reference_name",fn:function(ref){
              var item = ref.item;
return [(item.status_reference_name)?_c('v-chip',{attrs:{"color":_vm.getColorStatus(item.status_reference_name),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.status_reference_name)+" ")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.status_document_name",fn:function(ref){
              var item = ref.item;
return [(item.status_document_name)?_c('v-chip',{attrs:{"color":_vm.getColorStatus(item.status_document_name),"dark":"","small":""}},[_vm._v(" "+_vm._s(item.status_document_name)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [(item.status_reference_name == 'Error')?_c('v-tooltip',{attrs:{"max-width":"300","color":"#000","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-text-box-multiple-outline ")])],1)]}}],null,true)},[_c('span',[_c('strong',[_vm._v("OBSERVACIÓN: ")]),_vm._v(" "+_vm._s(item.observations)+" ")])]):_vm._e()]}}])})],1)],1)],1)],1),_c('Alert',{attrs:{"open":_vm.message.dialog,"text":_vm.message.sms,"title":_vm.message.title,"type":_vm.message.type,"redirect":_vm.message.redirect},on:{"close":function($event){_vm.message.dialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }